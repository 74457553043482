@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* DATAGRID */
/* colore Header */
.MuiDataGrid-container--top [role="row"] {
  background: #125ea0 !important;
  color: #d3d3d3 !important;
}
.MuiDataGrid-columnHeaderTitleContainer {
  background: #125ea0 !important;
  color: #d3d3d3 !important;
}
.MuiDataGrid-columnHeader {
  background: #125ea0 !important;
  color: #d3d3d3 !important;
}
.MuiDataGrid-columnHeaders > .MuiDataGrid-filler {
  background: #125ea0 !important;
  color: #d3d3d3 !important;
}
/* colore Header */
.css-1essi2g-MuiDataGrid-columnHeaderRow {
  background: #125ea0 !important;
  color: #d3d3d3 !important;
}
/* colore Pinned left */
.MuiDataGrid-columnHeader--pinnedLeft {
  background: #125ea0 !important;
  color: #d3d3d3 !important;
}
.MuiDataGrid-columnHeaders {
  background: #125ea0 !important;
  color: #d3d3d3 !important;
}
/* BORDER */
.css-qcqlck-MuiDataGrid-root {
  border-width: 0px !important;
}
.MuiDataGrid-main > *:first-of-type {
  border-width: 1px !important;
}
.MuiDataGrid-root {
  border-color: white !important;
}
/* QUICK SEARCH */
.css-3be3ve-MuiFormControl-root-MuiTextField-root-MuiDataGrid-toolbarQuickFilter
  > input {
  color: black !important;
  border-color: black !important;
}
.css-3be3ve-MuiFormControl-root-MuiTextField-root-MuiDataGrid-toolbarQuickFilter {
  padding: 0px !important;
}
.MuiInput-root {
  /* background-color: #125ea0 !important; */
  padding: 5px 10px !important;
  background-color: white !important;
  border-width: 1px !important;
  border-color: rgb(156 163 175) !important;
  border-radius: 8px !important;
}
.MuiInput-root:hover {
  border-color: black !important;
}
.MuiInput-root::before {
  border-width: 0px !important;
}
.MuiInput-root::after {
  border-width: 0px !important;
  appearance: none !important;
}
.MuiInput-underline:before {
  border-width: 0px !important;
}

/* Header */
/* @apply border-0 text-slate-200 bg-sinistri-blue; */
div > .css-yrdy0g-MuiDataGrid-columnHeaderRow {
  background-color: #125ea0 !important;
  color: #dedede !important;
  font-size: 14px !important;
  border: none;
}

div > .css-5wly58-MuiDataGrid-root {
  background-color: #125ea0 !important;
  color: #dedede !important;
  font-size: 14px !important;
  border: none;
}
div > .css-204u17-MuiDataGrid-main {
  @apply border;
}
div > .css-1sn8t1h-MuiDataGrid-footerContainer,
div > .css-wop1k0-MuiDataGrid-footerContainer {
  @apply border;
}

/* Buttons */
.btn-base {
  @apply flex items-center justify-center capitalize font-medium text-slate-200 px-3 py-1.5 text-sm md:text-sm rounded-sm text-center hover:scale-90 duration-300 transition-all;
}

.button-icon {
  @apply text-xl md:text-2xl;
}

.principal-button {
  @apply flex items-center justify-center capitalize font-medium px-3 py-1.5 text-sm md:text-base rounded-md text-center hover:scale-90 duration-300 transition-all hover:bg-transparent  border-slate-200 border text-slate-200  bg-sinistri-blue hover:bg-sinistri-blue hover:text-slate-200 z-40;
}

.success-button {
  @apply flex items-center justify-center capitalize font-medium text-sinistri-blue px-2 py-2 text-sm md:text-sm rounded-md text-center hover:scale-90 duration-300 transition-all bg-transparent border-transparent border border-sinistri-blue hover:bg-sinistri-blue hover:text-slate-200;
}

.success-button-disabled {
  @apply flex items-center justify-center capitalize font-medium text-sinistri-blue px-2 py-2 text-sm md:text-sm rounded-md text-center  bg-gray-300 border-transparent border border-sinistri-blue;
}

.error-button {
  @apply flex items-center justify-center capitalize font-medium text-red-500 px-2 py-2 text-sm md:text-sm rounded-md text-center hover:scale-90 duration-300 transition-all bg-transparent border-transparent border border-red-500 hover:bg-red-500 hover:text-slate-200;
}

.edit-button {
  @apply flex items-center justify-center capitalize font-medium text-sinistri-blue px-2 py-2 text-sm md:text-lg rounded-md text-center hover:scale-90 duration-300 transition-all bg-transparent border-transparent border hover:border-sinistri-blue;
}

.delete-button {
  @apply flex items-center justify-center capitalize font-medium text-red-800 px-2 py-2 text-sm md:text-sm rounded-md text-center hover:scale-90 duration-300 transition-all bg-transparent border-transparent border hover:border-red-500;
}

.nested-button {
  @apply flex items-center justify-center gap-3 capitalize font-semibold text-sinistri-blue px-5 py-1.5 text-base hover:bg-slate-200 w-full;
}

/* Body */
.page-container {
  @apply overflow-y-auto h-[calc(100vh_-_152px)] lg:h-[calc(100vh_-_74px)] w-full relative pt-1 md:px-1 px-3 rounded-md;
}

.el-container {
  @apply relative bg-sinistri-blue  p-2 rounded-md max-h-full min-h-full z-50 overflow-y-hidden;
}

/*  */
.breadcrumb-link {
  @apply flex flex-nowrap cursor-pointer p-2 hover:bg-white rounded-md text-slate-200 hover:text-sinistri-blue text-sm;
}

/* HOME GRAFICI */
.container-box {
  @apply flex flex-col p-2  w-full;
}

/* NAV BAR  */

.li-container {
  @apply flex items-center justify-center  p-2 md:space-x-3 rounded-md hover:bg-slate-200 cursor-pointer;
}
.tab-icon {
  @apply text-slate-200 text-2xl;
}
.li-icon {
  @apply text-slate-200  group-hover:text-sinistri-blue  text-2xl;
}
.li-text {
  @apply text-slate-200  group-hover:text-sinistri-blue font-light text-center md:text-lg text-xl duration-300 transition-all capitalize;
}
.child-li-container {
  @apply flex items-center justify-center md:justify-start py-1 px-2 md:space-x-3 rounded-md  border border-slate-200 group-hover:bg-slate-200 cursor-pointer;
}
.child-li-icon {
  @apply text-slate-200 group-hover:text-sinistri-blue text-xl;
}
.child-li-text {
  @apply text-slate-200 group-hover:text-sinistri-blue font-semibold text-center lg:text-left md:text-sm text-lg duration-300 transition-all capitalize;
}
/* BUTTON TEXT */
.text-green {
  @apply text-sm text-white group-hover:text-sinistri-darkgreen;
}
.text-red {
  @apply text-sm text-white group-hover:text-sinistri-red;
}
.text-container-green {
  @apply border-2 border-sinistri-lightgreen duration-300 bg-sinistri-lightgreen rounded-md py-1 px-2 cursor-pointer hover:bg-white;
}
.text-container-red {
  @apply border-2 border-sinistri-red duration-300 bg-sinistri-red rounded-md py-1 px-2 cursor-pointer hover:bg-white;
}

/* BUTTON ICONS */
.icon-green {
  @apply text-2xl text-white group-hover:text-sinistri-darkgreen;
}
.icon-reverse-green {
  @apply text-xl group-hover:text-white text-sinistri-darkgreen;
}
.icon-red {
  @apply text-2xl text-white group-hover:text-sinistri-red;
}
.icon-blue {
  @apply text-2xl text-white group-hover:text-sinistri-blue;
}
.icon-container-green {
  @apply border-2 border-sinistri-lightgreen duration-300 bg-sinistri-lightgreen rounded-md p-2 cursor-pointer hover:bg-white;
}
.icon-container-reverse-green {
  @apply border-2 border-white duration-300 hover:bg-sinistri-lightgreen rounded-md p-2 cursor-pointer bg-white;
}
.icon-container-red {
  @apply border-2 border-sinistri-red duration-300 bg-sinistri-red rounded-md p-2 cursor-pointer hover:bg-white;
}
.icon-container-blue {
  @apply border-2 border-sinistri-blue duration-300 bg-sinistri-blue rounded-md p-2 cursor-pointer hover:bg-white;
}
.icon-green-min {
  @apply text-xl text-white group-hover:text-sinistri-darkgreen;
}
.icon-container-green-min {
  @apply border-2 border-sinistri-lightgreen duration-300 bg-sinistri-lightgreen rounded-md p-1 cursor-pointer hover:bg-white;
}
.input {
  @apply w-full py-2 px-5 pl-8 bg-white border-gray-400 hover:border-black border rounded-md placeholder:text-black outline-none cursor-pointer;
}
.input-stato {
  @apply w-full py-2 px-5 pl-8 bg-white border-gray-400 hover:border-black border rounded-md placeholder:text-black cursor-pointer;
}
.select {
  @apply w-full py-2 px-5 pl-8 bg-white border-gray-400 hover:border-black border rounded-md placeholder:text-black outline-none cursor-pointer;
}
.input-data {
  @apply w-full py-2 px-5 pl-8 bg-white border-gray-400 hover:border-black border rounded-md placeholder:text-black cursor-pointer;
}

/* DOCUMENTAZIONE */
.icon-documentazioni {
  @apply text-6xl mx-auto cursor-pointer hover:scale-125 duration-300;
}
.icon-documentazioni-new {
  @apply text-3xl cursor-pointer duration-300;
}
/* TAB PANEL */
.icon-tab-panel {
  @apply text-sinistri-lightgreen text-lg sm:text-2xl lg:text-4xl;
}

/* Tools */
div > .css-1knaqv7-MuiButtonBase-root-MuiButton-root {
  @apply success-button;
}
div > .css-63ie9g-MuiButtonBase-root-MuiButton-root {
  @apply success-button;
}
/* div > .css-3be3ve-MuiFormControl-root-MuiTextField-root-MuiDataGrid-toolbarQuickFilter{
@apply relative flex items-center rounded-md focus-within:shadow-lg bg-white overflow-hidden p-0
} */
.css-v4u5dn-MuiInputBase-root-MuiInput-root > .css-ptiqhd-MuiSvgIcon-root {
  @apply h-6 w-6;
}

body.loading {
  cursor: wait !important;
}
